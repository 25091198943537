import { Link, graphql } from "gatsby"
import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import  ContentRenderer  from '../components/contentRenderer'
import { Icon } from '@iconify/react';
import styled from "styled-components"
import Seo from "../components/seo"

const ContainerNavNews = styled.div`
margin-top:80px;  
display:flex;
  gap:10px;
  align-items: center;
  justify-content:space-between;
  transition: all .2s ease-in;
  color:#957DB3;
  &:before {
    display:none;
  }
  /*&:hover {
    .title {color:var(--color-primary)};
  }*/
.nav-previous-item {max-width:50%;}
.nav-helper span { color:#957DB3;}
.title {display:inline-block}
`;

const FlexLinkBack = styled(Link)`
  display:inline-flex;
  gap:10px;
  align-items: center;
  transition: all .2s ease-in;
  &:before {
    display:none;
  }
  &:hover {
    gap:15px;
    svg path {
      color:var(--color-collectif);
    }
   

}
`;


const NewsPage = (props) => {
// console.log("PROPS: ", props) 
  return (
    <Layout location={props.path}>
      <Seo meta={props.data.datoCmsNews.seoMetaTags} />
      <GatsbyImage image={props.data.datoCmsNews.imagePrincipale.gatsbyImageData} alt="" style={{marginBottom:"30px"}}/>
      <FlexLinkBack to="/actualites" title="retour">
        <Icon icon ="bx:arrow-back" style={{ color: 'var(--color-primary)', fontSize:'30px' }}/>
        <span>Retour aux actualités</span>
      </FlexLinkBack>
      <h1>{props.data.datoCmsNews.titre}</h1>
      <span className="date_news"><Icon icon = "akar-icons:calendar" style={{width:"24px"}} />{props.data.datoCmsNews.meta.publishedAt}</span>
      <ContentRenderer content={props.data.datoCmsNews.contenu} /> 
      
      <ContainerNavNews>
        <div className="nav-previous-item">
            {props.pageContext.previous && (
              <Link to={`/actualites/${props.pageContext.previous.slug}`} title="actualité précédente"   >
                  <div className="nav-helper">
                    <span>News précédente</span> 
                  </div>
                  <div className="title">{props.pageContext.previous.titre}</div>
              </Link>
            )}
          </div>
          <div className="nav-previous-item">
            {props.pageContext.next && (
              <Link to={`/actualites/${props.pageContext.next.slug}`}  title="actualité suivante" >
                  <div className="nav-helper">
                    <span>News suivante</span> 
                  </div>
                  <div className="title">{props.pageContext.next.titre}</div >
              </Link>
            )}
          </div>
        </ContainerNavNews>


    </Layout>
  )
}

export const fragmentNews = graphql`
  fragment NewsContent on DatoCmsNews {
   
    titre
    slug
    meta {
      publishedAt(locale:"fr", formatString: "DD MMM YYYY")
      firstPublishedAt(locale:"fr", formatString: "DD MMM YYYY")
    }
    originalId
    id
    imagePrincipale {
      gatsbyImageData (
        width:450,
        height:205,
        imgixParams:  {fit: "crop", ar: "16/9", crop: "focalpoint", duotone:"4E2680,66C4D6"} 
   
      )
    }

    resume {
      ... on DatoCmsArticle {
        ...Article
      }   
    }

    contenu {
        ... on DatoCmsArticle {
            ...Article
        }   
        ... on DatoCmsGrid {
            ...Grid
        }
        
    }  
  }
`

export const query = graphql`
  query NewsQuery($pageIdJim: String) {
    datoCmsNews(originalId: { eq: $pageIdJim }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
        id
        titre
        slug
        meta {
          publishedAt(locale:"fr", formatString: "DD MMM YYYY")
          firstPublishedAt(locale:"fr", formatString: "DD MMM YYYY")
        }
        originalId
        imagePrincipale {
          gatsbyImageData(
          height:450,
          width:1220,
          imgixParams:  {fit: "crop", ar: "16/2", crop: "focalpoint", duotone:"4E2680,66C4D6"} 
          )
        }
        contenu {
            ... on DatoCmsArticle {
                ...Article
            }   
            ... on DatoCmsGrid {
                ...Grid
            }
        }  
        }
  }
`

export default NewsPage 
